import React, { useRef } from 'react'
import { DocsPage } from '../components'
 
const DocsPostTemplate = ({ pageContext, location }) => {
  const contentRef = useRef()

  return (
    <DocsPage 
      location={location} 
      pageContext={pageContext} 
      contentRef={contentRef} 
    />
  )
}

export default DocsPostTemplate
